<template>
  <b-modal
    id="add-user-modal"
    :visible="show"
    class="pt-2"
    size="md"
    :title="title"
    ok-only
    no-close-on-backdrop
    :ok-title="$t('Accept')"
    :cancel-title="$t('Cancel')"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent>
        <validation-provider
          #default="{ errors }"
          name="user"
          rules="required"
        >
          <b-form-group
            :label="$t('Users')"
            class="text-bold"
          >
            <v-select
              v-model="form.selected_users"
              multiple
              :options="users"
              :reduce="item => item.uuid"
              :placeholder="$t('Select Users')"
              label="name"
            />
          </b-form-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form>
    </validation-observer>
    <template #modal-footer>
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="primary"
            :disabled="isProcessing"
            @click="handleOk"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            <span v-if="form.id">
              {{ $t('Update') }}
            </span>
            <span v-else>
              {{ $t('Save') }}
            </span>
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import {
  BFormGroup, BForm, BButton, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useApollo from '@/plugins/graphql/useApollo'
import { required } from '@validations'
import vSelect from 'vue-select'

const initialForm = () => ({
  id: null,
  selected_users: [],
})

export default {
  components: {
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BSpinner,
    vSelect,
  },
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    teamInfo: {
      type: Object,
      required: true,
    },
    members: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      title: this.$t('Add Member'),
      isProcessing: false,
      form: {
        ...initialForm(),
      },
      users: [],
      required,
    }
  },
  mounted() {
    this.form.selected_users = this.members.map(m => m.uuid)
  },
  created() {
    useApollo.project.getAllProjectUser({
      uuid: this.teamInfo.project.uuid,
      first: -1,
    }).then(response => {
      this.users = response.data.project.users.data
    })
  },
  methods: {
    handleOk() {
      this.isProcessing = true
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          useApollo.team.addTeamMembers({
            userUids: this.form.selected_users.filter(user => !!user),
            teamUid: this.teamInfo.uuid,
            projectUid: this.teamInfo.project.uuid,
          }).then(response => {
            this.showSuccess(response.data.assignUsersToTeam.message)
            this.$emit('updateTeamCount', this.form.selected_users.length)
            this.$emit('close')
            this.$bvModal.hide('add-user-modal')
          }).finally(() => { this.isProcessing = false })
        }
      })
    },
    // handleUpdate(formData) {
    // },
  },

}
</script>
