<template>
  <div>
    <b-row class="align-items-center">
      <b-col
        cols="8"
        md="4"
      >
        <b-overlay :show="isLoading">
          <b-form-group :label="$t('Project')">
            <v-select
              v-model="selectedProject"
              :width="500"
              :options="projects"
              :reduce="item => item.uuid"
              :placeholder="$t('Select Project')"
              label="name"
            />
          </b-form-group>
        </b-overlay>
      </b-col>
      <b-col
        cols="4"
        md="8"
      >
        <b-button
          variant="primary"
          class="pull-right"
          @click="showModal = true"
        >
          {{ $t('Add Team') }}
        </b-button>
      </b-col>
    </b-row>
    <create-update
      :show="showModal"
      @close="showModal = false"
      @onSave="getTeam"
    />

    <!-- Skeleton Loader -->
    <project-skeleton
      v-if="isLoading"
      :counts="3"
    />

    <!-- Content -->
    <div v-else>
      <b-row
        v-if="teams.length"
        class="pt-4"
      >
        <b-col
          v-for="team of teams"
          :key="team.uuid"
          size="md-4"
          class="mt-3 col-md-4"
        >
          <team-card :value="team" />
        </b-col>
      </b-row>

      <div v-else>
        <b-alert
          class="p-2 text-center"
          show
        >
          <feather-icon
            icon="InfoIcon"
            size="24"
          />
          <h5 class="d-inline-block ml-1">
            {{ $t('No Teams Found!') }}
          </h5>
        </b-alert>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BButton, BFormGroup, BOverlay, BAlert,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import vSelect from 'vue-select'
import ProjectSkeleton from '@/views/common/components/ProjectSkeleton.vue'
import CreateUpdate from './update.vue'
import TeamCard from './components/teamCard.vue'

export default {
  components: {
    BButton,
    BFormGroup,
    BOverlay,
    BAlert,
    CreateUpdate,
    vSelect,
    TeamCard,
    ProjectSkeleton,
  },
  data() {
    return {
      showModal: false,
      projects: [],
      selectedProject: null,
      teams: [],
      showAddTeamModel: false,
      isLoading: false,
    }
  },
  watch: {
    selectedProject: {
      handler(newValue) {
        if (newValue) {
          this.getTeam()
        }
      },
      immediate: true,
    },
  },
  created() {
    this.isLoading = true
    useApollo.project.getProjects({ first: -1 }).then(response => {
      this.projects = response.data.me.projects.data
      this.selectedProject = this.$store.state.project.selectedProject
    }).catch(error => {
      this.showErrorMessage(error)
    })
  },
  methods: {
    getTeam() {
      this.isLoading = true
      useApollo.team.getTeam({
        uuid: this.selectedProject,
      }).then(response => {
        this.teams = response.data.project.teams.data
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>
