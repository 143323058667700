<template>
  <b-modal
    ref="create-project-modal"
    :visible="show"
    class="pt-2"
    size="md"
    :title="title"
    ok-only
    :ok-title="$t('Accept')"
    :cancel-title="$t('Cancel')"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent>
        <validation-provider
          #default="{ errors }"
          name="first name"
          rules="required"
        >
          <b-form-group
            :label="$t('Team Name')"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="FolderIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="form.name"
                :placeholder="$t('Team Title')"
                :state="errors.length > 0 ? false:null"
              />
            </b-input-group>
          </b-form-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
        <validation-provider
          #default="{ errors }"
          name="project"
          rules="required"
        >
          <b-form-group
            :label="$t('Project')"
            class="text-bold"
          >
            <v-select
              v-model="form.project"
              :options="projects"
              :reduce="item => item.uuid"
              :placeholder="$t('Select Project')"
              label="name"
            />
          </b-form-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
        <b-form-group
          :label="$t('Team Description')"
        >
          <b-input-group class="input-group-merge">
            <b-form-textarea
              v-model="form.description"
              rows="8"
              :placeholder="$t('Team Description')"
            />
          </b-input-group>
        </b-form-group>
      </b-form>
    </validation-observer>
    <template #modal-footer="{ }">
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="primary"
            :disabled="isProcessing"
            @click="handleOk"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            <span v-if="form.id">
              {{ $t('Update') }}
            </span>
            <span v-else>
              {{ $t('Save') }}
            </span>
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import {
  BFormGroup, BFormInput, BForm, BInputGroup, BInputGroupPrepend, BFormTextarea, BButton, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useApollo from '@/plugins/graphql/useApollo'
import { required } from '@validations'
import vSelect from 'vue-select'

const initialForm = () => ({
  id: null,
  name: '',
  description: '',
  project: null,
})

export default {
  components: {
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BSpinner,
    vSelect,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      title: this.$t('Create Team'),
      isProcessing: false,
      form: {
        ...initialForm(),
      },
      projects: [],
      required,
    }
  },
  created() {
    useApollo.project.getProjects().then(response => {
      this.projects = response.data.me.projects.data
    }).catch(error => {
      this.showErrorMessage(error)
    })
  },
  mounted() {
  },
  methods: {
    handleOk() {
      this.isProcessing = true
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          useApollo.team.storeTeam({
            projectUid: this.form.project,
            name: this.form.name,
            description: this.form.description,
          }).then(() => {
            this.$emit('onSave')
            this.showSuccessMessage({
              data: {
                message: 'Team created',
              },
            })
            this.$emit('close')
          }).catch(error => {
            this.showErrorMessage(error)
          }).finally(() => { this.isProcessing = false })
        }
      })
    },
    handleUpdate(formData) {
      useApollo.project.updateproject(formData).then(response => {
        this.$emit('updateProjectList', response.data.updateproject, true)
        this.closeModal()
        this.showSuccessMessage({
          data: {
            message: 'Project successfully updated',
          },
        })
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => { this.isProcessing = false })
    },
  },

}
</script>
